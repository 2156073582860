<template>
  <div>
    <div class="bannerContainer">
      <div class="w" :class="$i18n.locale == 'en' ? 'en_banner' : 'zh_banner'">
        <div class="title">
          <div>{{ $t('原木精确智能') }}</div>
          <div>{{ $t('检尺解决方案') }}</div>
        </div>
        <div class="info">
          {{ $t('可快速、精确测算出每根原木的直径和体积。') }}
        </div>
      </div>
    </div>

    <div class="scheme">
      <div class="schemeInfo">
        <div class="title">{{ $t('方案简介') }}</div>
        <div class="info">
          {{
            $t(
              'Tensor Scaler创新性地利用计算机视觉技术实现原木检尺，只需对准木材拍摄一张照片，即可快速、精确测算出每根原木的直径和体积。先进的AI算法确保达到人工平均水准的测量精度，帮用户严格把关。Tensor Scaler是目前最快速的原木测量解决方案。一次测量800根木材30秒可出结果，大幅度削减对人力的需求。'
            )
          }}
        </div>
      </div>
      <div class="technicalSpecification">
        <div class="title">{{ $t('技术参数') }}</div>
        <div class="img">
          <img
            v-show="$i18n.locale == 'zh'"
            src="../../../assets/原木精确智能检尺解决方案/技术参数.jpg.png"
            alt=""
          />
          <img
            v-show="$i18n.locale == 'en'"
            src="../../../assets/原木精确智能检尺解决方案/技术参数en.jpg.png"
            alt=""
          />
        </div>
      </div>
    </div>

    <div class="usageScenarioContainer">
      <div class="usageScenario w">
        <div class="title">{{ $t('使用场景') }}</div>
        <div class="img">
          <img
            src="../../../assets/原木精确智能检尺解决方案/使用场景.png"
            alt=""
          />
        </div>
        <div :class="$i18n.locale == 'en' ? 'en_text_box' : 'zh_text_box'">
          <div class="text">{{ $t('地面堆垛检尺') }}</div>
          <div class="text">{{ $t('夹抱机检尺') }}</div>
          <div class="text">{{ $t('卡车木材检尺') }}</div>
          <div class="text">{{ $t('集装箱木材检尺') }}</div>
        </div>
      </div>
    </div>

    <div class="deploymentMethodsContainer">
      <div class="deploymentMethods w">
        <div class="title">{{ $t('部署方式') }}</div>
        <div class="content">
          <img
            src="../../../assets/原木精确智能检尺解决方案/部署方式.png"
            alt=""
          />
          <div class="text_box">
            <div class="item">
              <div class="title">{{ $t('固定式部署') }}</div>
              <div class="text_info">
                {{
                  $t(
                    '固定部署在闸口的龙门桁架上，机械臂在XYZ3个自由度上运动，对准木材横截面拍照，完成测量。适用于大型用户。'
                  )
                }}
              </div>
            </div>
            <div class="item">
              <div class="title">{{ $t('便携式部署') }}</div>
              <div class="text_info">
                {{
                  $t(
                    '安装在三脚架上，便于移动。适用于作业地点不固定的场景或小型用户'
                  )
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="measuringResultContainer">
      <div class="measuringResult w">
        <div class="title">{{ $t('测量结果') }}</div>
        <div class="content">
          <img
            src="../../../assets/原木精确智能检尺解决方案/测量结果.png"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.bannerContainer {
  background: url(../../../assets/原木精确智能检尺解决方案/首页配图.png)
    no-repeat center;
  background-size: cover;
  .zh_banner {
    padding: 122px 0px 0px 134px;
    height: 480px;
    color: #fff;
    .title {
      line-height: 104.26px;
      font-size: 72px;
      font-weight: 700;
    }
    .info {
      color: #fff;
      font-size: 24px;
      height: 83px;
      font-size: 24px;
      line-height: 34.75px;
    }
  }
  .en_banner {
    padding: 122px 0px 0px 134px;
    height: 480px;
    color: #fff;
    .title {
      line-height: 69.5px;
      font-size: 48px;
      font-weight: 700;
    }
    .info {
      width: 507px;
      color: #fff;
      height: 83px;
      font-size: 24px;
      line-height: 34.75px;
    }
  }
}

.scheme {
  background: #f5f6fa;
  .title {
    padding: 45px 0px 30px 0px;
    font-size: 25px;
    font-weight: 500;
    line-height: 36.2px;
    color: rgba(52, 79, 152, 1);
  }
  .schemeInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    .info {
      text-align: justify;
      width: 1070px;
      font-size: 20px;
      font-weight: 400;
      line-height: 28.96px;
      color: rgba(128, 128, 128, 1);
    }
  }
  .technicalSpecification {
    display: flex;
    flex-direction: column;
    align-items: center;
    .img {
      width: 1062px;
      height: 445px;
      margin-bottom: 60px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.usageScenarioContainer {
  background: #f5f6fa;
  .usageScenario {
    .title {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60px;
      font-size: 25px;
      font-weight: 500;
      line-height: 36.2px;
      color: #344f98;
    }
    .img {
      padding: 32px 155.74px 0px 148px;
      display: flex;
      justify-content: space-between;
      img {
        width: 100%;
      }
    }
    .zh_text_box {
      background: #f5f6fa;
      padding: 10px 202px 60px 206px;
      display: flex;
      justify-content: space-between;
      .text {
        text-align: center;
        font-size: 20px;
        font-weight: 500;
        line-height: 28.96px;
        color: rgba(128, 128, 128, 1);
      }
    }
    .en_text_box {
      background: #f5f6fa;
      padding: 10px 226px 60px 186px;
      display: flex;
      justify-content: space-between;
      .text {
        text-align: center;
        font-size: 20px;
        font-weight: 500;
        line-height: 28.96px;
        color: rgba(128, 128, 128, 1);
      }
    }
  }
}

.deploymentMethodsContainer {
  background: #f5f6fa;
  .deploymentMethods {
    .title {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60px;
      font-size: 25px;
      font-weight: 500;
      line-height: 36.2px;
      color: #344f98;
    }
    .content {
      padding: 32px 156px 60px 145px;
      img {
        width: 100%;
      }
      .text_box {
        display: flex;
        justify-content: space-between;
        .item {
          width: 500px;
          .title {
            font-size: 20px;
            font-weight: 500;
            line-height: 28.96px;
            color: #344f98;
          }
          .text_info {
            text-align: justify;
            font-size: 18px;
            font-weight: 400;
            line-height: 26.06px;
            color: #808080;
          }
        }
      }
    }
  }
}

.measuringResultContainer {
  background: #f5f6fa;
  .measuringResult {
    .title {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60px;
      font-size: 25px;
      font-weight: 500;
      line-height: 36.2px;
      color: #344f98;
    }
    .content {
      padding: 32px 78px 56px 77px;
      img {
        width: 100%;
      }
    }
  }
}
</style>
